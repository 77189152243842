import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import firebase from "../../../../setup/firebase";
import { IoCashOutline } from "react-icons/io5";
import { BsFillRecordFill } from "react-icons/bs";
import _ from "lodash";
import { useAddBet, usePlayerRoomSelected } from "../hooks";
import { formatDate, isNumber, numberWithCommas, withNumber } from "helper";
import { toastError } from "react-geek-toast";
import Cookies from "js-cookie";
import { HiChevronLeft, HiLockClosed, HiLockOpen } from "react-icons/hi";
import ReactPlayer from "react-player";
import Button from "partial/components/Button";
import { useHistory, useParams } from "react-router-dom";

function PlayerRoomDetail() {
  const match = useParams();
  const history = useHistory();

  const [form, setForm] = useState({});
  const [currentDate, setCurrentDate] = useState();
  const [playerWallet, setPlayerWallet] = useState();
  const [bettingStatus, setBettingStatus] = useState(false);
  const [round, setRound] = useState("");
  const [winners, setWinners] = useState([]);
  const [sumOfBets, setSumOfBets] = useState([]);
  const [playerBets, setPlayerBets] = useState([]);
  const [totalBetOfPlayers, setTotalBetOfPlayers] = useState(0);
  const [bettingTypes, setBettingTypes] = useState([]);
  const [horseCount, setHorseCount] = useState(0);
  //daily double
  const [dailyDoubleRace1, setDailyDoubleRace1] = useState("");
  const [dailyDoubleRace2, setDailyDoubleRace2] = useState("");

  //daily double plus 1
  const [dailyDoubleP1Race1, setDailyDoubleP1Race1] = useState("");
  const [dailyDoubleP1Race2, setDailyDoubleP1Race2] = useState("");
  const [dailyDoubleP1Race3, setDailyDoubleP1Race3] = useState("");

  //extra double
  const [extraDoubleRace1, setExtraDoubleRace1] = useState("");
  const [extraDoubleRace2, setExtraDoubleRace2] = useState("");

  //pick 5 , pick 6, wta
  const [exoticRace1, setExoticRace1] = useState("");
  const [exoticRace2, setExoticRace2] = useState("");
  const [exoticRace3, setExoticRace3] = useState("");
  const [exoticRace4, setExoticRace4] = useState("");
  const [exoticRace5, setExoticRace5] = useState("");
  const [exoticRace6, setExoticRace6] = useState("");
  const [exoticRace7, setExoticRace7] = useState("");

  const profile = JSON.parse(Cookies.get("_user")) || {};

  const [, gameRoomDetails] = usePlayerRoomSelected(match.id);

  const sessionId =
    Cookies.get("_sessionId") || _.get(gameRoomDetails, "data.id");

  React.useEffect(() => {
    if (!_.isEmpty(gameRoomDetails)) {

      setBettingStatus(
        _.get(gameRoomDetails, "data.attributes.betting_status")
      );

      if (_.get(gameRoomDetails, "data.attributes.game") !== null) {
        setRound(_.get(gameRoomDetails, "data.attributes.game.round"));
        setHorseCount(_.get(gameRoomDetails, "data.attributes.game.horse_count"));
        //daily double

        const ddrace1 = parseInt(
          _.get(gameRoomDetails, "data.attributes.game.round")
        );
        const ddrace2 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 1;

        setDailyDoubleRace1("Race " + ddrace1);
        setDailyDoubleRace2("Race " + ddrace2);

        //daily double plus 1

        const ddp1race1 = parseInt(
          _.get(gameRoomDetails, "data.attributes.game.round")
        );
        const ddp1race2 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 1;
        const ddp1race3 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 2;

        setDailyDoubleP1Race1("Race " + ddp1race1);
        setDailyDoubleP1Race2("Race " + ddp1race2);
        setDailyDoubleP1Race3("Race " + ddp1race3);

        //extra double

        const edrace1 = parseInt(
          _.get(gameRoomDetails, "data.attributes.game.round")
        );
        const edrace2 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 2;

        setExtraDoubleRace1("Race " + edrace1);
        setExtraDoubleRace2("Race " + edrace2);

        //exotics

        const exotic1 = parseInt(
          _.get(gameRoomDetails, "data.attributes.game.round")
        );
        const exotic2 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 1;
        const exotic3 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 2;
        const exotic4 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 3;
        const exotic5 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 4;
        const exotic6 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 5;
        const exotic7 =
          parseInt(_.get(gameRoomDetails, "data.attributes.game.round")) + 6;

        setExoticRace1("Race " + exotic1);
        setExoticRace2("Race " + exotic2);
        setExoticRace3("Race " + exotic3);
        setExoticRace4("Race " + exotic4);
        setExoticRace5("Race " + exotic5);
        setExoticRace6("Race " + exotic6);
        setExoticRace7("Race " + exotic7);

        let types = "";
        let finalVal = [];
        if (
          _.get(
            gameRoomDetails,
            "data.attributes.game.available_bet_types"
          ).indexOf(",") > -1
        ) {
          types = _.get(
            gameRoomDetails,
            "data.attributes.game.available_bet_types"
          ).split(",");
          finalVal = Object.keys(types).map((x, index) => ({
            value: types[x],
            label: x,
          }));
          setBettingTypes(finalVal);
        } else {
          types = _.get(
            gameRoomDetails,
            "data.attributes.game.available_bet_types"
          );
          setBettingTypes([
            {
              value: types,
              label: types,
            },
          ]);
        }
      }
    }
  }, [gameRoomDetails]);

  // const [, walletDetails] = usePlayerWalletDetails();

  React.useEffect(() => {
    setCurrentDate(formatDate(new Date(), "MMM DD, YYYY hh:mm A"));
  }, []);

  const createMarkup = (text) => {
    const printableText = text.replace(/(?:\r\n|\r|\n)/g, "<br />");
    return { __html: printableText };
  };

  const [videoUrl, setVideoUrl] = useState("");

  React.useEffect(() => {
    if (_.get(gameRoomDetails, "data.attributes.type_video") === "YOUTUBE") {
      const finalUrl = `${_.get(
        gameRoomDetails,
        "data.attributes.url"
      )}?disablekb=1&frameborder=0&autoplay=1`;
      setVideoUrl(finalUrl.toString().replace("watch?v=", "embed/"));
    }
  }, [gameRoomDetails]);

  React.useEffect(() => {
    if (_.get(gameRoomDetails, "data.attributes.type_video") === "FACEBOOK") {
      setVideoUrl(_.get(gameRoomDetails, "data.attributes.url"));
    }
  }, [gameRoomDetails]);

  useEffect(() => {
    // build reference
    const bettingStatusApi = firebase
      .database()
      .ref("rooms/" + match.id + "/session/" + sessionId + "/betting_status");

    bettingStatusApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          setBettingStatus(data);
        }
      },
      (error) => {
        toastError(error);
      }
    );

    return () => bettingStatusApi.off();
  }, [match.id, sessionId]);

  useEffect(() => {
    // build reference
    const gameRoomDetailsAPi = firebase.database().ref("rooms/" + match.id + "/session/" + sessionId + "/game/details");

    gameRoomDetailsAPi.on("value",
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          setHorseCount(data.horse_count);
          let types = "";
          let finalVal = [];
          if (data.available_bet_types.indexOf(",") > -1) {
            types = data.available_bet_types.split(",");
            finalVal = Object.keys(types).map((x, index) => ({
              value: types[x],
              label: x,
            }));
            setBettingTypes(finalVal);
          } else {
            types = data.available_bet_types;
            setBettingTypes([
              {
                value: types,
                label: types,
              },
            ]);
          }
        }
      },
      (error) => {
        toastError(error);
      }
    );

    return () => gameRoomDetailsAPi.off();
  }, [match.id, sessionId]);

  useEffect(() => {
    // build reference
    const roundApi = firebase
      .database()
      .ref("rooms/" + match.id + "/session/" + sessionId + "/rounds");

    roundApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          setRound(data);
        }
      },
      (error) => {
        toastError(error);
      }
    );

    return () => roundApi.off();
  }, [match.id, sessionId]);

  useEffect(() => {
    // build reference
    const walletApi = firebase
      .database()
      .ref("user/" + profile.id + "/balance/");

    walletApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          setPlayerWallet(data);
        }
      },
      (error) => {
        toastError(error);
      }
    );

    return () => walletApi.off();
  }, [profile.id]);

  useEffect(() => {
    // build reference
    const stateApi = firebase
      .database()
      .ref("rooms/" + match.id + "/session/" + sessionId + "/state");
    stateApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        if (data === false) {
          backToRoom();
          Cookies.remove("_sessionId");
        }
      },
      (error) => {
        toastError(error);
      }
    );

    return () => stateApi.off();
  }, [match.id, sessionId]);

  useEffect(() => {
    // build reference
    const last5WinsApi = firebase
      .database()
      .ref("rooms/" + match.id + "/session/" + sessionId + "/lastwins");

    last5WinsApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();

        if (data !== null) {
          let entries = {};
          let finalArr = [];
          let finalWinners = [];

          entries = Object.entries(data);
          for (const [, val] of entries) {
            finalArr.push(val);
          }

          let totalWinners = [];
          totalWinners = Object.keys(finalArr[0]).map((x) => ({
            data: finalArr[0][x],
            round: x,
          }));

          totalWinners.forEach(function (item) {
            var items = Object.values(item.data);
            finalWinners.push({
              data: items,
              round: item.round,
            });
          });

          setWinners(finalWinners);
          // setShowWin(true);
        } else {
          setWinners([]);
          // setShowWin(false);
        }
      },
      (error) => {
        toastError(error);
      }
    );

    return () => last5WinsApi.off();
  }, []);

  useEffect(() => {
    // build reference
    const betsPlayerApi = firebase
      .database()
      .ref("rooms/" + match.id + "/session/" + sessionId + "/game/bets");

    betsPlayerApi.on(
      "value",
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) {
          const data = snapshot.val();

          let entries = {};
          let finalArr = [];

          if (!_.isEmpty(data)) {
            entries = Object.entries(data);
            for (const [, val] of entries) {
              finalArr.push(val[0]);
            }
          }

          const ids = finalArr.map((o) => o.id);
          const filtered = finalArr.filter(
            ({ id }, index) => !ids.includes(id, index + 1)
          );

          const playerBetsOnly = filtered
            .filter((obj) => obj.player_user_id === profile.id)
            .map((obj) => obj);

          setPlayerBets(playerBetsOnly);

          let finalbets = [];

          const sumInArray = getTotalAmount(filtered);

          setTotalBetOfPlayers(sumInArray);

          playerBetsOnly.forEach((element) => {
            finalbets.push(element);
          });

          var result = finalbets.reduce(function (acc, val) {
            var o = acc
              .filter(function (obj) {
                return obj.type === val.type && obj.horse === val.horse;
              })
              .pop() || { type: val.type, horse: val.horse, amount: 0 };

            o.amount += val.amount;
            acc.push(o);
            return acc;
          }, []);

          if (result.length > 0) {
            let filteredArray = _.uniqWith(result, _.isEqual);

            setSumOfBets(filteredArray);
          }
        }
      },
      (error) => {
        toastError(error);
      }
    );

    return () => betsPlayerApi.off();
  }, [match.id, sessionId]);

  useEffect(() => {

    const betsPlayerApi = firebase
      .database()
      .ref("rooms/" + match.id + "/session/" + sessionId + "/game/bets");

    betsPlayerApi.get().then(
      (snapshot) => {
        if (snapshot.exists()) {
          betsPlayerApi.once("value", (snapshot) => {
            const data = snapshot.val();
            let entries = {};
            let finalArr = [];

            if (!_.isEmpty(data)) {
              entries = Object.entries(data);
              for (const [, val] of entries) {
                finalArr.push(val[0]);
              }
            }

            const ids = finalArr.map((o) => o.id);
            const filtered = finalArr.filter(
              ({ id }, index) => !ids.includes(id, index + 1)
            );

            const playerBetsOnly = filtered
              .filter((obj) => obj.player_user_id === profile.id)
              .map((obj) => obj);

            setPlayerBets(playerBetsOnly);

            let finalbets = [];

            playerBetsOnly.forEach((element) => {
              finalbets.push(element);
            });

            var result = finalbets.reduce(function (acc, val) {
              var o = acc
                .filter(function (obj) {
                  return obj.type === val.type && obj.horse === val.horse;
                })
                .pop() || { type: val.type, horse: val.horse, amount: 0 };

              o.amount += val.amount;
              acc.push(o);
              return acc;
            }, []);

            if (result.length > 0) {
              let filteredArray = _.uniqWith(result, _.isEqual);

              setSumOfBets(filteredArray);
            }
          });
        } else {
          betsPlayerApi.once("value", (snapshot) => {
            const data = snapshot.val();

            let entries = {};
            let finalArr = [];

            if (!_.isEmpty(data)) {
              entries = Object.entries(data);
              for (const [, val] of entries) {
                finalArr.push(val[0]);
              }
            }

            const ids = finalArr.map((o) => o.id);
            const filtered = finalArr.filter(
              ({ id }, index) => !ids.includes(id, index + 1)
            );

            const playerBetsOnly = filtered
              .filter((obj) => obj.player_user_id === profile.id)
              .map((obj) => obj);

            setPlayerBets(playerBetsOnly);

            let finalbets = [];

            playerBetsOnly.forEach((element) => {
              finalbets.push(element);
            });

            var result = finalbets.reduce(function (acc, val) {
              var o = acc
                .filter(function (obj) {
                  return obj.type === val.type && obj.horse === val.horse;
                })
                .pop() || { type: val.type, horse: val.horse, amount: 0 };

              o.amount += val.amount;
              acc.push(o);
              return acc;
            }, []);

            if (result.length > 0) {
              let filteredArray = _.uniqWith(result, _.isEqual);

              setSumOfBets(filteredArray);
            }
          });
        }

        betsPlayerApi.on("child_added", (snapshot) => {
          // const data = snapshot.val();

          betsPlayerApi.get().then((snapshot) => {
            if (snapshot.exists()) {
              betsPlayerApi.once("value", (snapshot) => {
                const data = snapshot.val();
                let entries = {};
                let finalArr = [];

                if (!_.isEmpty(data)) {
                  entries = Object.entries(data);
                  for (const [, val] of entries) {
                    finalArr.push(val[0]);
                  }
                }

                const ids = finalArr.map((o) => o.id);
                const filtered = finalArr.filter(
                  ({ id }, index) => !ids.includes(id, index + 1)
                );

                const playerBetsOnly = filtered
                  .filter((obj) => obj.player_user_id === profile.id)
                  .map((obj) => obj);

                setPlayerBets(playerBetsOnly);

                let finalbets = [];

                playerBetsOnly.forEach((element) => {
                  finalbets.push(element);
                });

                var result = finalbets.reduce(function (acc, val) {
                  var o = acc
                    .filter(function (obj) {
                      return obj.type === val.type && obj.horse === val.horse;
                    })
                    .pop() || { type: val.type, horse: val.horse, amount: 0 };

                  o.amount += val.amount;
                  acc.push(o);
                  return acc;
                }, []);

                if (result.length > 0) {
                  let filteredArray = _.uniqWith(result, _.isEqual);

                  setSumOfBets(filteredArray);
                }
              });
            }
          });
        });
      },
      (error) => {
        toastError(error);
      }
    );

    return () => betsPlayerApi.off();
  }, [match.id, sessionId]);

  useEffect(() => {
    // build reference
    const betsUpdateApi = firebase
      .database()
      .ref("rooms/" + match.id + "/session/" + sessionId + "/game/bets");

    betsUpdateApi.get().then(
      (snapshot) => {

        betsUpdateApi.on("value", (snapshot) => {
          const data = snapshot.val();

          betsUpdateApi.get().then((snapshot) => {
            if (snapshot.exists()) {
              betsUpdateApi.once("value", (snapshot) => {
                const data = snapshot.val();

                let entries = {};
                let finalArr = [];

                if (!_.isEmpty(data)) {
                  entries = Object.entries(data);
                  for (const [, val] of entries) {
                    finalArr.push(val[0]);
                  }
                }

                const ids = finalArr.map((o) => o.id);
                const filtered = finalArr.filter(
                  ({ id }, index) => !ids.includes(id, index + 1)
                );

                const playerBetsOnly = filtered
                  .filter((obj) => obj.player_user_id === profile.id)
                  .map((obj) => obj);

                setPlayerBets(playerBetsOnly);

                const sumInArray = getTotalAmount(filtered);

                setTotalBetOfPlayers(sumInArray);

                let finalbets = [];

                playerBetsOnly.forEach((element) => {
                  finalbets.push(element);
                });

                var result = finalbets.reduce(function (acc, val) {
                  var o = acc
                    .filter(function (obj) {
                      return obj.type === val.type && obj.horse === val.horse;
                    })
                    .pop() || { type: val.type, horse: val.horse, amount: 0 };

                  o.amount += val.amount;
                  acc.push(o);
                  return acc;
                }, []);

                if (result.length > 0) {
                  let filteredArray = _.uniqWith(result, _.isEqual);
                  setSumOfBets(filteredArray);
                }
              });
            }
          });
        });
      },
      (error) => {
        toastError(error);
      }
    );

    return () => betsUpdateApi.off();
  }, [match.id, sessionId]);

  const backToRoom = () => (e) => {
    e.preventDefault();
    history.push("/player/room");
    Cookies.remove("_sessionId");
  };

  const [showInput, setShowInput] = useState("");

  const selectType = (row) => (e) => {
    setShowInput(row);
    setForm({
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      type: row,
    });
    var finalSum = sumOfBets
      .filter((obj) => obj.type === row)
      .map((obj) => obj);

    const result = finalSum.reduce((data, obj) => {
      return data + obj.amount;
    }, 0);

    setTotalBetOfPlayers(result);
  };

  const setAmount = (row) => (e) => {
    setForm({
      ...form,
      amount: row,
    });
  };

  const onChangeAmount = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onChange = (e) => {
    const value = Math.min(20, Math.max(0, Number(e.target.value)));

    if (Number(e.target.value) >= 20) {
      setForm({
        ...form,
        [e.target.name]: value,
      });
    }
    if (Number(e.target.value) < 20) {
      setForm({
        ...form,
        [e.target.name]: Number(e.target.value),
      });
    }
  };

  const onChangePick5 = (e) => {
    if (isNumber(e.target.value)) {
      setForm({
        ...form,
        [e.target.name]: e.target.value.replace(/^0+/, ''),
      });
    }
  };

  const handleOnChange = (e) => {
    const value = Math.min(horseCount, Math.max(0, Number(e.target.value)));

    if (Number(e.target.value) >= Number(horseCount)) {
      setForm({
        ...form,
        [e.target.name]: value,
      });
    }
    if (Number(e.target.value) < Number(horseCount)) {
      setForm({
        ...form,
        [e.target.name]: Number(e.target.value),
      });
    }
  };

  const [isAddBet, addBet] = useAddBet();

  const handleSubmit = (e) => {
    e.preventDefault();
    let horseInput = "";

    if (showInput === "Forecast") {
      if (form.num1 === undefined || form.num2 === undefined) {
        toastError("Please enter your bet");
      } else {
        horseInput = form.num1 + "," + form.num2;
        const args = {
          ...form,
          horse: horseInput,
        };
        addBet(args, match.id, (newData) => {
          setForm({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            num6: "",
            amount: "",
          });
          setShowInput("");
        });
      }
    } else if (showInput === "Daily Double") {
      if (form.num1 === undefined || form.num2 === undefined) {
        toastError("Please enter your bet");
      } else {
        horseInput = form.num1 + "," + form.num2;
        const args = {
          ...form,
          horse: horseInput,
        };
        addBet(args, match.id, (newData) => {
          setForm({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            num6: "",
            amount: "",
          });
          setShowInput("");
        });
      }
    } else if (showInput === "Daily Double Plus 1") {
      if (
        form.num1 === undefined ||
        form.num2 === undefined ||
        form.num3 === undefined
      ) {
        toastError("Please enter your bet");
      } else {
        horseInput = form.num1 + "," + form.num2 + "," + form.num3;
        const args = {
          ...form,
          horse: horseInput,
        };
        addBet(args, match.id, (newData) => {
          setForm({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            num6: "",
            amount: "",
          });
          setShowInput("");
        });
      }
    } else if (showInput === "Extra Double") {
      if (form.num1 === undefined || form.num2 === undefined) {
        toastError("Please enter your bet");
      } else {
        horseInput = form.num1 + "," + form.num2;
        const args = {
          ...form,
          horse: horseInput,
        };
        addBet(args, match.id, (newData) => {
          setForm({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            num6: "",
            amount: "",
          });
          setShowInput("");
        });
      }
    } else if (showInput === "Pick 5") {
      if (
        form.num1 === undefined ||
        form.num2 === undefined ||
        form.num3 === undefined ||
        form.num4 === undefined ||
        form.num5 === undefined
      ) {
        toastError("Please enter your bet");
      }
      if (
        withNumber(form.num1) === false ||
        withNumber(form.num2) === false ||
        withNumber(form.num3) === false ||
        withNumber(form.num4) === false ||
        withNumber(form.num5) === false
      ) {
        toastError("Please enter complete number");
      } else {
        horseInput =
          form.num1 +
          "|" +
          form.num2 +
          "|" +
          form.num3 +
          "|" +
          form.num4 +
          "|" +
          form.num5;
        const args = {
          ...form,
          horse: horseInput,
        };
        addBet(args, match.id, (newData) => {
          setForm({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            num6: "",
            amount: "",
          });
          setShowInput("");
        });
      }
    } else if (showInput === "Pick 6") {
      if (
        form.num1 === undefined ||
        form.num2 === undefined ||
        form.num3 === undefined ||
        form.num4 === undefined ||
        form.num5 === undefined ||
        form.num6 === undefined
      ) {
        toastError("Please enter your bet");
      }
      if (
        withNumber(form.num1) === false ||
        withNumber(form.num2) === false ||
        withNumber(form.num3) === false ||
        withNumber(form.num4) === false ||
        withNumber(form.num5) === false ||
        withNumber(form.num6) === false
      ) {
        toastError("Please enter complete number");
      } else {
        horseInput =
          form.num1 +
          "|" +
          form.num2 +
          "|" +
          form.num3 +
          "|" +
          form.num4 +
          "|" +
          form.num5 +
          "|" +
          form.num6;
        const args = {
          ...form,
          horse: horseInput,
        };
        addBet(args, match.id, (newData) => {
          setForm({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            num6: "",
            amount: "",
          });
          setShowInput("");
        });
      }
    } else if (showInput === "Winner Takes All") {
      if (
        form.num1 === undefined ||
        form.num2 === undefined ||
        form.num3 === undefined ||
        form.num4 === undefined ||
        form.num5 === undefined ||
        form.num6 === undefined ||
        form.num7 === undefined
      ) {
        toastError("Please enter your bet");
      }
      if (
        withNumber(form.num1) === false ||
        withNumber(form.num2) === false ||
        withNumber(form.num3) === false ||
        withNumber(form.num4) === false ||
        withNumber(form.num5) === false ||
        withNumber(form.num6) === false ||
        withNumber(form.num7) === false
      ) {
        toastError("Please enter complete number");
      } else {
        horseInput =
          form.num1 +
          "|" +
          form.num2 +
          "|" +
          form.num3 +
          "|" +
          form.num4 +
          "|" +
          form.num5 +
          "|" +
          form.num6 +
          "|" +
          form.num7;
        const args = {
          ...form,
          horse: horseInput,
        };
        addBet(args, match.id, (newData) => {
          setForm({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            num6: "",
            num7: "",
            amount: "",
          });
          setShowInput("");
        });
      }
    }
  };

  const getTotalAmount = (sumData) => {
    let amount = 0;
    amount = sumData.reduce(
      (totalAmount, x) => totalAmount + parseFloat(x.amount),
      0
    );
    return amount;
  };

  return (
    <>
      <div className="flex flex-col space-x-0 space-y-2 sm:space-y-0 sm:flex-row items-center sm:px-4 px-2 sm:pt-4 pt-2 sm:pb-0 pb-2 sm:space-x-5 justify-between bg-gray-900 sm:border-none border-gray-600">
        <div className="border-b sm:border-none pt-2 sm:pt-0 border-gray-700 sm:w-auto w-full pb-2 sm:pb-0">
          <Button
            primary
            className="mr-auto sm:mr-0"
            onClick={backToRoom()}
            icon={HiChevronLeft}
            type="button"
            size="sm"
          >
            Back to Rooms
          </Button>
        </div>
        <div className="sm:space-x-2 gap-2 grid sm:grid-cols-3 grid-cols-2">
          <div className="flex items-center col-span-1 sm:bg-gray-800 bg-none py-3 px-2 sm:rounded-lg rounded-none sm:border-r border-none border-gray-700">
            <div className="px-2">
              <div>
                <div className="text-gray-300 text-xs">My Wallet</div>
                <div className="text-secondary-500 font-bold text-lg">
                  {playerWallet} PHP
                </div>
              </div>
            </div>
            {/* <div className="px-2">
            <Link>
              <HiCog className="w-7 h-7 flex-shrink-0 text-white hover:text-secondary-500 transition" />
            </Link>
          </div> */}
          </div>
          <div className="flex items-center col-span-1 sm:bg-gray-800 bg-none py-3 px-2 rounded-none sm:rounded-lg">
            <div className="px-2">
              <div>
                <div className="text-gray-300 text-xs whitespace-nowrap">
                  Current Room
                </div>
                <div className="text-secondary-500 font-bold text-lg">
                  {_.get(gameRoomDetails, "data.attributes.room.name")}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center sm:col-span-1 col-span-2 flex-col justify-end border-t sm:border-none pt-2 sm:pt-0 border-gray-700">
            <div className="flex items-center space-x-2">
              <div className="text-lg sm:text-2xl text-white font-bold">
                Race {round} of{" "}
                {_.get(gameRoomDetails, "data.attributes.number_of_rounds")}
              </div>
              <div className="px-2 bg-red-500 text-white rounded-sm">
                <div className="flex items-center space-x-1">
                  <BsFillRecordFill className="w-3 h-3 flex-shrink-0 p-px border border-white rounded-full" />
                  <div>Live</div>
                </div>
              </div>
            </div>
            <div className="text-white/50 w-full text-center sm:text-right mb-4">
              Today, {currentDate}
            </div>
          </div>
        </div>
        <div className="col-12 d-flex">
          <div className="flex items-center space-x-2">
            {!bettingStatus && (
              <div className="flex items-center font-semibold text-xl px-3 py-2 rounded-lg space-x-2 bg-gradient-to-tr from-gray-900 via-red-900/50 to-gray-900 text-red-400">
                <HiLockClosed className="w-4 h-4 flex-shrink-0" />
                <div>Betting is closed.</div>
              </div>
            )}
            {bettingStatus && (
              <div className="flex items-center font-semibold text-xl px-3 py-2 rounded-lg space-x-2 bg-gradient-to-tr from-gray-900 via-green-900 to-gray-900/50 text-green-400">
                <HiLockOpen className="w-4 h-4 flex-shrink-0" />
                <div className="text-white">You may now start betting. </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="space-y-4 sm:px-4 sm:py-4 pt-0 pb-4 bg-gray-900 flex-1">
        <div className="bg-gray-900 sm:p-5 p-0 sm:rounded-lg border-none sm:border border-gray-800 rounded-none">
          <div className="grid sm:grid-cols-5 grid-cols-1">
            <div className="sm:col-span-3 col-span-1 sm:h-96 h-60 flex items-center justify-center">
              <div className="text-lg text-gray-500 w-full h-full">
                {_.get(gameRoomDetails, "data.attributes.type_video") ===
                  "YOUTUBE" && (
                    <iframe
                      width="100%"
                      height="100%"
                      src={videoUrl}
                      name="youtube embed"
                      allow="autoplay; encrypted-media"
                    ></iframe>
                  )}
                {_.get(gameRoomDetails, "data.attributes.type_video") ===
                  "FACEBOOK" && (
                    <ReactPlayer
                      controls
                      className="react-player"
                      url={videoUrl}
                      width="100%"
                      height="100%"
                      config={{
                        facebook: {
                          attributes: {
                            width: "560px",
                            height: "315px",
                            padding: "0",
                            backgroundcolor: "#000",
                          },
                        },
                      }}
                    />
                  )}
              </div>
            </div>
            <div className="game-details sm:col-span-2 col-span-1 h-96 p-5 sm:flex bg-white overflow-y-auto hidden ">
              <div
                // eslint-disable-next-line
                dangerouslySetInnerHTML={createMarkup(
                  _.get(gameRoomDetails, "data.attributes.race_description") ||
                  "--"
                )}
              />
            </div>
          </div>
        </div>

        <div className="bg-gray-900 overflow-hidden sm:rounded-lg rounded-0">
          <div className="grid sm:grid-cols-5 grid-cols-1 sm:divide-x divide-x-0 divide-gray-700 gap-2 sm:gap-0 bg-gray-800 border rounded-lg border-gray-800">
            <div className="col-span-1 order-2 sm:order-1 sm:rounded-l-lg rounded-none overflow-hidden">
              <div className="text-white font-bold px-3 py-4 uppercase bg-gradient-to-bl from-primary-600 to-primary-800">
                My Bets
              </div>
              <div className="bg-gray-800 max-h-[400px] overflow-x-auto overflow-y-auto overflow-hidden">
                {(playerBets || []).map((row) => (
                  <div
                    key={row.id}
                    className="race-item divide-y divide-white/10 border-b-4 border-gray-900 my-1"
                  >
                    <div className="flex items-center space-x-2 justify-between px-3 py-1">
                      <div className="tracking-wide font-bold text-white py-1 text-base whitespace-nowrap">
                        Race {row.draw_round}
                      </div>
                      <div className="text-gray-400 text-xs text-right">
                        Start Time:{" "}
                        {formatDate(row.created_at, "MMM DD, YYYY hh:mm A")}
                      </div>
                    </div>
                    <div className="px-3 py-2">
                      <div className="text-gray-300"> {row.type} </div>
                      <div className="flex items-center space-x-2 justify-between">
                        <div className="flex items-center space-x-1">
                          <div className="text-green-900 bg-green-500 tracking-widest px-1.5 py-0.5 text-sm rounded-sm font-bold">
                            {row.horse}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2 justify-between">
                        <div className="flex items-center space-x-1">
                          <div className="tracking-widest text-gray-100 px-1.5 py-0.5 text-sm rounded-sm font-bold">
                            Amount:
                          </div>
                        </div>
                        <div>
                          <div className="text-gray-100">
                            {numberWithCommas(
                              parseFloat(row.amount).toFixed(2)
                            )}
                          </div>
                          <div className="text-green-500 uppercase text-xs font-bold tracking-wide text-right">
                            {row.is_win === 0 ? "" : "Win"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="sm:col-span-3 col-span-1 sm:p-10 p-4 order-1 sm:order-2 bg-gray-900 overflow-hidden">
              <div className="text-center space-y-5">
                <div className="relative max-w-xl mx-auto">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-700" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="px-2 bg-gray-900 text-base font-bold text-secondary-500 py-1 uppercase leading-none">
                      Place your bets for Race {round}
                    </span>
                  </div>
                </div>
                <div className="inline-flex flex-wrap flex-row justify-center mx-auto gap-1 p-1">
                  {(bettingTypes || []).map((row) => (
                    <button
                      key={row.label}
                      onClick={selectType(row.value)}
                      className={`bg-gray-800 text-white text-base rounded-lg px-4 py-2 hover:bg-gray-700 transition whitespace-nowrap ${row.value === showInput && "active"
                        }`}
                    >
                      {row.value}
                    </button>
                  ))}
                </div>
                {showInput === "Forecast" && (
                  <>
                    <div className="space-y-2 justify-center">
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <span className="text-white"> First Place </span>
                        <div className="flex items-center space-x-2">
                          <input
                            name="num1"
                            type="text"
                            placeholder="0"
                            value={_.get(form, "num1")}
                            onChange={handleOnChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 w-32 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <span className="text-white"> Second Place </span>
                        <div className="flex items-center space-x-2">
                          <input
                            name="num2"
                            type="text"
                            placeholder="0"
                            value={_.get(form, "num2")}
                            onChange={handleOnChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 w-32 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 justify-center">
                      <span className="text-white text-lg font-bold">
                        Total Bets:
                      </span>
                      <div className="text-2xl font-bold text-secondary-500">
                        {numberWithCommas(
                          parseFloat(totalBetOfPlayers).toFixed(2)
                        )}
                      </div>
                    </div>
                  </>
                )}
                {showInput === "Daily Double" && (
                  <>
                    <div className="space-y-2 justify-center">
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num1"
                            type="text"
                            placeholder={dailyDoubleRace1}
                            value={_.get(form, "num1")}
                            onChange={onChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num2"
                            type="text"
                            placeholder={dailyDoubleRace2}
                            value={_.get(form, "num2")}
                            onChange={onChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 justify-center">
                      <span className="text-white text-lg font-bold">
                        Total Bets:
                      </span>
                      <div className="text-2xl font-bold text-secondary-500">
                        {numberWithCommas(totalBetOfPlayers)}
                      </div>
                    </div>
                  </>
                )}
                {showInput === "Daily Double Plus 1" && (
                  <>
                    <div className="space-y-2 justify-center">
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num1"
                            type="text"
                            placeholder={dailyDoubleP1Race1}
                            value={_.get(form, "num1")}
                            onChange={onChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num2"
                            type="text"
                            placeholder={dailyDoubleP1Race2}
                            value={_.get(form, "num2")}
                            onChange={onChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num3"
                            type="text"
                            placeholder={dailyDoubleP1Race3}
                            value={_.get(form, "num3")}
                            onChange={onChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 justify-center">
                      <span className="text-white text-lg font-bold">
                        Total Bets:
                      </span>
                      <div className="text-2xl font-bold text-secondary-500">
                        {numberWithCommas(totalBetOfPlayers)}
                      </div>
                    </div>
                  </>
                )}
                {showInput === "Extra Double" && (
                  <>
                    <div className="space-y-2 justify-center">
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num1"
                            type="text"
                            placeholder={extraDoubleRace1}
                            value={_.get(form, "num1")}
                            onChange={onChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num2"
                            type="text"
                            placeholder={extraDoubleRace2}
                            value={_.get(form, "num2")}
                            onChange={onChange}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 justify-center">
                      <span className="text-white text-lg font-bold">
                        Total Bets:
                      </span>
                      <div className="text-2xl font-bold text-secondary-500">
                        {numberWithCommas(totalBetOfPlayers)}
                      </div>
                    </div>
                  </>
                )}
                {showInput === "Pick 5" && (
                  <>
                    <div className="space-y-2 justify-center">
                      <div className="mb-5">
                        <span className="text-white text-medium">
                          If you are going to bet multiple horses in 1 race
                          please put comma (,) separator per horse number{" "}
                        </span>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num1"
                            type="text"
                            placeholder={exoticRace1}
                            value={_.get(form, "num1")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num2"
                            type="text"
                            placeholder={exoticRace2}
                            value={_.get(form, "num2")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num3"
                            type="text"
                            placeholder={exoticRace3}
                            value={_.get(form, "num3")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num4"
                            type="text"
                            placeholder={exoticRace4}
                            value={_.get(form, "num4")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num5"
                            type="text"
                            placeholder={exoticRace5}
                            value={_.get(form, "num5")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 justify-center">
                      <span className="text-white text-lg font-bold">
                        Total Bets:
                      </span>
                      <div className="text-2xl font-bold text-secondary-500">
                        {numberWithCommas(totalBetOfPlayers)}
                      </div>
                    </div>
                  </>
                )}
                {showInput === "Pick 6" && (
                  <>
                    <div className="space-y-2 justify-center">
                      <div className="mb-5">
                        <span className="text-white text-medium">
                          If you are going to bet multiple horses in 1 race
                          please put comma (,) separator per horse number{" "}
                        </span>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num1"
                            type="text"
                            placeholder={exoticRace1}
                            value={_.get(form, "num1")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num2"
                            type="text"
                            placeholder={exoticRace2}
                            value={_.get(form, "num2")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num3"
                            type="text"
                            placeholder={exoticRace3}
                            value={_.get(form, "num3")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num4"
                            type="text"
                            placeholder={exoticRace4}
                            value={_.get(form, "num4")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num5"
                            type="text"
                            placeholder={exoticRace5}
                            value={_.get(form, "num5")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num6"
                            type="text"
                            placeholder={exoticRace6}
                            value={_.get(form, "num6")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 justify-center">
                      <span className="text-white text-lg font-bold">
                        Total Bets:
                      </span>
                      <div className="text-2xl font-bold text-secondary-500">
                        {numberWithCommas(totalBetOfPlayers)}
                      </div>
                    </div>
                  </>
                )}
                {showInput === "Winner Takes All" && (
                  <>
                    <div className="space-y-2 justify-center">
                      <div className="mb-5">
                        <span className="text-white text-medium">
                          If you are going to bet multiple horses in 1 race
                          please put comma (,) separator per horse number{" "}
                        </span>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num1"
                            type="text"
                            placeholder={exoticRace1}
                            value={_.get(form, "num1")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num2"
                            type="text"
                            placeholder={exoticRace2}
                            value={_.get(form, "num2")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num3"
                            type="text"
                            placeholder={exoticRace3}
                            value={_.get(form, "num3")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num4"
                            type="text"
                            placeholder={exoticRace4}
                            value={_.get(form, "num4")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num5"
                            type="text"
                            placeholder={exoticRace5}
                            value={_.get(form, "num5")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num6"
                            type="text"
                            placeholder={exoticRace6}
                            value={_.get(form, "num6")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                      <div className="flex max-w-xs items-center justify-between mx-auto bg-gray-800 rounded-lg overflow-hidden pl-4">
                        <div className="flex items-center space-x-2">
                          <input
                            name="num7"
                            type="text"
                            placeholder={exoticRace7}
                            value={_.get(form, "num7")}
                            onChange={onChangePick5}
                            className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 justify-center">
                      <span className="text-white text-lg font-bold">
                        Total Bets:
                      </span>
                      <div className="text-2xl font-bold text-secondary-500">
                        {numberWithCommas(totalBetOfPlayers)}
                      </div>
                    </div>
                  </>
                )}
                <div className="relative max-w-xl mx-auto">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-700" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="px-2 bg-gray-900 text-base font-bold text-secondary-500 py-1 uppercase leading-none">
                      How much would you like to play for?
                    </span>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="inline-flex mx-auto items-center space-x-2 bg-gray-800 rounded-lg px-5 justify-between w-full max-w-sm">
                    <div className="text-gray-300 font-normal text-base">
                      Bet Amount
                    </div>
                    <div className="flex items-center space-x-2">
                      <input
                        name="amount"
                        type="number"
                        placeholder="0"
                        value={_.get(form, "amount")}
                        onChange={onChangeAmount}
                        className="bg-gray-800 border-b border-gray-500 focus:border-secondary-500 placeholder-gray-600 text-secondary-500 w-32 text-2xl font-semibold text-right pl-0 pr-4 transition border-0 focus:ring-0"
                      />
                      <div className="text-gray-500">PHP</div>
                    </div>
                  </div>
                  <div className="text-sm text-gray-300 max-w-sm mx-auto">
                    Enter an amount or choose from any of the denominations
                    below:
                  </div>
                  <div className="flex max-w-lg gap-2 flex-wrap justify-center mx-auto">
                    <button
                      type="button"
                      onClick={setAmount("10")}
                      className="bg-gray-300 font-semibold text-sm rounded-sm px-4 py-2 hover:bg-secondary-500 transition"
                    >
                      10
                    </button>
                    <button
                      type="button"
                      onClick={setAmount("20")}
                      className="bg-gray-300 font-semibold text-sm rounded-sm px-4 py-2 hover:bg-secondary-500 transition"
                    >
                      20
                    </button>
                    <button
                      type="button"
                      onClick={setAmount("30")}
                      className="bg-gray-300 font-semibold text-sm rounded-sm px-4 py-2 hover:bg-secondary-500 transition"
                    >
                      30
                    </button>
                    <button
                      type="button"
                      onClick={setAmount("50")}
                      className="bg-gray-300 font-semibold text-sm rounded-sm px-4 py-2 hover:bg-secondary-500 transition"
                    >
                      50
                    </button>
                    <button
                      type="button"
                      onClick={setAmount("100")}
                      className="bg-gray-300 font-semibold text-sm rounded-sm px-4 py-2 hover:bg-secondary-500 transition"
                    >
                      100
                    </button>
                    <button
                      type="button"
                      onClick={setAmount("200")}
                      className="bg-gray-300 font-semibold text-sm rounded-sm px-4 py-2 hover:bg-secondary-500 transition"
                    >
                      200
                    </button>
                    <button
                      type="button"
                      onClick={setAmount("500")}
                      className="bg-gray-300 font-semibold text-sm rounded-sm px-4 py-2 hover:bg-secondary-500 transition"
                    >
                      500
                    </button>
                  </div>
                  <button
                    disabled={isAddBet}
                    onClick={handleSubmit}
                    type="button"
                    className="bg-green-500 font-bold text-lg px-7 py-3 rounded-md text-black hover:bg-green-400 transition"
                  >
                    <div className="flex items-center space-x-2">
                      <div className="">Bet Now</div>
                      <IoCashOutline className="w-6 h-6 flex-shrink-0" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-span-1 order-3 sm:order-3 sm:rounded-r-lg rounded-none overflow-hidden">
              <div className="text-white font-bold px-3 py-4 uppercase bg-gradient-to-bl from-green-600 to-green-800">
                Race Results
              </div>
              <div className="max-h-[400px] overflow-y-auto overflow-hidden">
                {(winners || []).map((row) => (
                  <div key={row.round} className="bg-gray-800">
                    {(row.data || []).map((item, i) => (
                      <React.Fragment key={`winners${i}`}>
                        <div className="race-item divide-y divide-white/10 border-b-4 border-gray-900 my-1">
                          <div className="flex items-center space-x-2 justify-between px-3 py-1">
                            <div className="tracking-wide font-bold text-white py-1 text-base whitespace-nowrap">
                              Race {row.round}
                            </div>
                            <div className="text-gray-400 text-xs text-right">
                              Declare Time: <br />
                              {formatDate(
                                item[0].created_at,
                                "MMM DD, YYYY hh:mm A"
                              )}
                            </div>
                          </div>
                          <div className="flex items-center space-x-2 justify-between px-3 py-1">
                            <div className="text-gray-300">
                              {item[0].bet_type}
                            </div>
                            <div className="text-gray-400 text-xs text-right">
                              Count: {item[0].count}
                            </div>
                          </div>
                          <div className="flex items-center space-x-2 justify-between px-3 py-1">
                            <div className="text-white bg-gray-600 tracking-widest px-1.5 py-0.5 text-sm rounded-sm font-bold">
                              {item[0].winning_horse}{" "}
                              <span className="text-white">
                                [x{item[0].multiplier}]{" "}
                              </span>
                            </div>
                            <div className="text-gray-400 text-xs text-right">
                              Total Amount: {item[0].total_amount}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-1 order-3 sm:order-3 sm:rounded-r-lg rounded-none overflow-hidden sm:hidden">
              <div className="text-white font-bold px-3 py-4 uppercase bg-gradient-to-bl from-red-600 to-red-800">
                Race Description
              </div>
            </div>
            <div className="game-details order-last col-span-1 h-96 sm:border border-0 border-gray-800 p-5 flex overflow-y-auto sm:hidden bg-white">
              <div
                // eslint-disable-next-line
                dangerouslySetInnerHTML={createMarkup(
                  _.get(gameRoomDetails, "data.attributes.race_description") ||
                  "--"
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PlayerRoomDetail.defaultProps = {
  sessionInfo: {},
  walletData: {},
};

PlayerRoomDetail.propTypes = {
  sessionInfo: PropTypes.instanceOf(Object),
  walletData: PropTypes.instanceOf(Object),
};

export default PlayerRoomDetail;
